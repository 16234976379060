import "./index.css";

const ChatWithUs = () => {
  window.open("https://api.whatsapp.com/send/?phone=%2B919079552080&text&type=phone_number&app_absent=0", "_blank");
};
const WhatsApp = () => {
  return (
    <div className="relative rounded-xl cursor-pointer">
      <img
        src={"/static/WhatsApp-icon.svg"}
        id="whatsapp_icon"
        alt="whatsapp icon"
        onClick={ChatWithUs}
        className="fixed shake-scale-animation  hover:scale-125 transition-all duration-300 h-40 w-40 md:h-32 md:w-32 bottom-1 left-5 pt-10 z-50"
      />
    </div>
  );
};
export default WhatsApp;
