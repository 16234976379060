import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaEnvelope,
  FaPhone,
  FaYoutube,
} from "react-icons/fa";
function Strip() {
  return (
    <div className="flex justify-between bg-black w-full p-2 container sm:hidden">
      <div className="flex justify-between ">
        <a
          href="mailto:mittalritesh607@gmail.com"
          className="flex items-center text-white ms-2"
        >
          <FaEnvelope className="me-2 text-golden" /> mittalritesh607@gmail.com
        </a>
        <a
          href="tel:+919079552080"
          className="flex items-center ms-2 text-white"
        >
          <FaPhone className="me-2 text-golden" /> (+91)9079552080
        </a>
      </div>
      <div className="flex justify-between">
        <a
          href="https://api.whatsapp.com/send/?phone=%2B919079552080&text&type=phone_number&app_absent=0"
          target="_blank"
          className="flex items-center ms-2"
          rel="noreferrer"
        >
          <span className="text-golden underline">WhatsApp</span>
        </a>
        <a
          href="https://www.facebook.com/people/Algo-Ninjas/61551997760834/?mibextid=ZbWKwL"
          target="_blank"
          className="flex items-center ms-2 text-white"
          rel="noreferrer"
        >
          <FaFacebook />
        </a>
        {/* <a
          href="https://www.youtube.com/@mlsclasses8293?si=KBojcjPosvKjfwjH"
          target="_blank"
          className="flex items-center ms-2 text-white"
          rel="noreferrer"
        >
          <FaYoutube />
        </a> */}
        <a
          href="https://www.instagram.com/algo.ninjas"
          target="_blank"
          className="flex items-center ms-2 text-white"
          rel="noreferrer"
        >
          <FaInstagram />
        </a>
      </div>
    </div>
  );
}

export default Strip;
