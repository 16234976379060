import React, { useEffect, useState, lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import LayoutTopBottom from "./layout/LayoutTopBottom";
import ScrollToTop from "./layout/ScrollToTop";

import PublicRoutes from "./routes/PublicRoutes";

const themes = ["light", "dark", "custom1", "custom2"];

function App() {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    const currentIndex = themes.indexOf(theme);
    const nextIndex = (currentIndex + 1) % themes.length;
    setTheme(themes[nextIndex]);
  };

  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route
          path="/*"
          element={
            <LayoutTopBottom>
              <PublicRoutes />
            </LayoutTopBottom>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
