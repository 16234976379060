import React from "react";
import { FaInstagram, FaWhatsapp, FaYoutube } from "react-icons/fa";
import { RiFacebookFill } from "react-icons/ri";
import { FaLinkedinIn } from "react-icons/fa";

const Icons = ({ icon, href }) => {
  return (
    <div className="w-10 flex justify-center items-center text-xl  h-10 bg-[#74abf9] rounded-full">
      <a href={href} target="_blank" rel="noreferrer">
        <p className="text-white">{icon} </p>
      </a>
    </div>
  );
};

export default function AppFooter() {
  return (
    <div className="">
      <div className="flex flex-wrap py-10 bg-[#151414] justify-evenly  sm:flex-col sm:items-center sm:flex-nowrap">
        <div className="flex flex-col sm:items-center mb-2">
          <a href="/" className="">
            <img
              src={"/static/logo.webp"}
              alt="mls logo"
              className=" h-36  "
            />
          </a>
          <div className="w-[23rem] text-start md:pl-3 text-[#D5D5D5]">
            Algo Ninjas offers Live, Online 1-1 Personalized Tutoring of Coding And Math.
          </div>
        </div>

        <div className="flex items-start flex-col mt-16 md:mt-0 text-white sm:items-center my-2 gap-2">
          <h2 className="text-lg font-bold">Company</h2>
          <div>
            <a href="/about" className="text-[#D5D5D5] ">
              About
            </a>
          </div>
          <div>
            <a href="/shipping" className="text-[#D5D5D5] ">
              Shipping
            </a>
          </div>
        </div>
        <div className="flex items-start flex-col sm:items-center mt-16 md:mt-0 my-2 gap-2">
          <h2 className="text-lg font-bold text-white">Reach us</h2>
          <div className="">
            <a href="mailto:mittalritesh607@gmail.com" className="text-[#D5D5D5] ">
              mittalritesh607@gmail.com
            </a>
          </div>
          <div>
            <a href="/contact-us" className="text-[#D5D5D5] ">
              Contact us
            </a>
          </div>
        </div>

        <div className="flex items-start flex-col sm:items-center mt-16 md:mt-0 my-2 gap-2">
          <div className="text-lg font-bold text-white">Other Resources</div>
          <div>
            <a href="/blogs" className="text-[#D5D5D5] ">
              Blogs
            </a>
          </div>
          <div>
            <a href="/resource-center" className="text-[#D5D5D5] ">
              Resources Center
            </a>
          </div>
          <div>
            <a href="/affiliats" className="text-[#D5D5D5] ">
              Affiliates
            </a>
          </div>
        </div>

        <div className="flex flex-col mt-10 lg:mt-0">
          <div className="text-2xl font-semibold text-start my-6">
            Follow us
          </div>
          <div className="flex gap-3">
            <Icons
              icon={<RiFacebookFill />}
              href={"https://www.facebook.com/profile.php?id=61551997760834&mibextid=ZbWKwL"}
            />
            <Icons
              icon={<FaInstagram />}
              href={
                "https://www.instagram.com/algo.ninjas"
              }
            />
            <Icons
              icon={<FaLinkedinIn />}
              href={"https://www.linkedin.com/company/algo-ninjas-jaipur/"}
            />
            {/* <Icons
              icon={<FaYoutube />}
              href={
                "https://www.youtube.com/@mlsclasses8293?si=KBojcjPosvKjfwjH"
              }
            /> */}
            <Icons
              icon={<FaWhatsapp />}
              href={"https://api.whatsapp.com/send/?phone=%2B919079552080&text&type=phone_number&app_absent=0"}
            />
          </div>
        </div>
      </div>
      <div className="w-100 bg-[#202020]  flex flex-col justify-between items-center py-6 gap-3 uppercase text-sm   ">
        <div>
          <ul className="flex sm:my-2 gap-5">
            <li className="ms-2">
              <a href="/terms" className="text-[#D5D5D5]">
                Terms &amp; Conditions
              </a>
            </li>
            <li className="ms-2">
              <a href="/privacy-policy" className="text-[#D5D5D5]">
                Privacy Policy
              </a>
            </li>
            <li className="ms-2">
              <a href="/cancel" className="text-[#D5D5D5]">
                Cancellation & Refund
              </a>
            </li>
          </ul>
        </div>
        <div class=" sm:my-2 w-full">
          <span className=" text-[#878787] w-full">
            Copyright © 2024 All Rights Reserved
            <a
              href="https://www.algoninjas.com/"
              target="_blank"
              rel="noreferrer"
              className="text-white ms-2"
            >
              algoninjas.com
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}

// Algo Ninjas offers Live, Online 1-1 Personalized Tutoring of Math And Coding.: Interactive 1:1 Online Classes: Digital SAT, ACT, AP,
// Common Core, IB & IGCSE.
