import React from "react";
import { Route, Routes } from "react-router-dom"; // Assuming you have a Layout component
import RoutesWrapper from "./RoutesWrapper";
import { Navigate } from "react-router-dom";
import PageNotFound from "../pages/PageNotFound";
const ElementaryMath = () => import("../pages/TestPrep/ElementaryMath");
const MiddleSchoolMath = () => import("../pages/TestPrep/MiddleSchoolMath");
const HighSchoolMath = () => import("../pages/TestPrep/HighSchoolMath");
const CommonCoreMath = () => import("../pages/TestPrep/CommonCoreMath");
// Lazy load the HomePage component
const HomePage = () => import("../pages/Home");
const PricingPage = () => import("../pages/Pricing");
const AboutPage = () => import("../pages/About");
const AffiliatesPage = () => import("../pages/Affiliates");
const BlogsPage = () => import("../pages/Blogs");
const BookTrialPage = () => import("../pages/BookTrial");
const ContactUsPage = () => import("../pages/ContactUs");
const PrivacyPolicyPage = () => import("../pages/PrivacyPolicy");
const ResourceCenterPage = () => import("../pages/ResourceCenter");
const ShippingPage = () => import("../pages/Shipping");
const StudentCornerPage = () => import("../pages/StudentCorner");
const TermsAndConditionsPage = () => import("../pages/TermsAndConditions");
// const APComponentPage = () => import("../pages/TestPrep/AP");
const CancelAndRefundPage = () => import("../pages/Cancel");

const ActMathPage = () => import("../pages/TestPrep/ACT/Math");

const DigitalSatPage = () => import("../pages/TestPrep/SAT/DigitalSat");
const SatMathPage = () => import("../pages/TestPrep/SAT/SatMath");

const MLSCSharpProgramminPage = () => import("../pages/ITCourses/CSharp");
const MLSCPPProgrammingPage = () => import("../pages/ITCourses/CPlus");
const MLSWebDevelopmentPage = () => import("../pages/ITCourses/Html");
const MLSJavaProgrammingPage = () => import("../pages/ITCourses/Java");
const MLSPythonProgrammingPage = () => import("../pages/ITCourses/Python");
const ScratchProgramming = () => import("../pages/ITCourses/scratch");
function PublicRoutes() {
  return (
    <Routes>
      <Route exact path="/" element={<RoutesWrapper component={HomePage} />} />
      {/* <Route
        path="/pricing"
        exact
        element={<RoutesWrapper component={PricingPage} />}
      /> */}
      <Route
        path="/about"
        exact
        element={<RoutesWrapper component={AboutPage} />}
      />
      <Route
        path="/affiliats"
        exact
        element={<RoutesWrapper component={AffiliatesPage} />}
      />
      <Route
        path="/blogs"
        exact
        element={<RoutesWrapper component={BlogsPage} />}
      />

      <Route
        path="/book-trial"
        exact
        element={<RoutesWrapper component={BookTrialPage} />}
      />
      <Route
        path="/contact-us"
        exact
        element={<RoutesWrapper component={ContactUsPage} />}
      />
      <Route
        path="/contact"
        exact
        element={<Navigate to="/contact-us" replace />}
      />
      <Route
        path="/privacy-policy"
        exact
        element={<RoutesWrapper component={PrivacyPolicyPage} />}
      />
      <Route
        path="/privacy"
        exact
        element={<Navigate to="/privacy-policy" replace />}
      />
      <Route
        path="/resource-center"
        exact
        element={<RoutesWrapper component={ResourceCenterPage} />}
      />
      <Route
        path="/shipping"
        exact
        element={<RoutesWrapper component={ShippingPage} />}
      />
      <Route
        path="/student-corner"
        exact
        element={<RoutesWrapper component={StudentCornerPage} />}
      />

      <Route
        path="/math/act-math"
        exact
        element={<RoutesWrapper component={ActMathPage} />}
      />

    <Route
        path="/math/elementary-math"
        exact
        element={<RoutesWrapper component={ElementaryMath} />}
      />
    <Route
        path="/math/middle-school-math"
        exact
        element={<RoutesWrapper component={MiddleSchoolMath} />}
      />
      <Route
        path="/math/high-school-math"
        exact
        element={<RoutesWrapper component={HighSchoolMath} />}
      />
      <Route
      path="/math/common-core-math"
      exact
      element={<RoutesWrapper component={CommonCoreMath} />}
    />
      <Route
        path="/math/sat-math"
        exact
        element={<RoutesWrapper component={SatMathPage} />}
      />

      <Route
        path="/terms"
        exact
        element={<RoutesWrapper component={TermsAndConditionsPage} />}
      />

      <Route
        path="/cancel"
        exact
        element={<RoutesWrapper component={CancelAndRefundPage} />}
      />
      <Route
        path="/coding/csharp-programming"
        exact
        element={<RoutesWrapper component={MLSCSharpProgramminPage} />}
      />
      <Route
        path="/coding/cplusplus-programming"
        exact
        element={<RoutesWrapper component={MLSCPPProgrammingPage} />}
      />
      <Route
        path="/coding/html-web-development"
        exact
        element={<RoutesWrapper component={MLSWebDevelopmentPage} />}
      />
      <Route
        path="/coding/java"
        exact
        element={<RoutesWrapper component={MLSJavaProgrammingPage} />}
      />
      <Route
        path="/coding/scratch"
        exact
        element={<RoutesWrapper component={ScratchProgramming} />}
      />
      <Route
        path="/coding/python"
        exact
        element={<RoutesWrapper component={MLSPythonProgrammingPage} />}
      />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default PublicRoutes;
